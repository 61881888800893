import companies from '../source/integrators';
import badgeIntegratorIcon from '../img/badge-integrator.png';
import badgeLogoIcon from '../img/badge-logo.png';

const events = {
  RADIO_CHANGED: 'radio_changed',
};

document.addEventListener('DOMContentLoaded', function() {
  const parentElement = document.getElementById('filtersol');

  function generateRadioButtons() {
    const filterSolutions = [...new Set(companies.map(c => c.solutions).flat())];

    filterSolutions.forEach(solution => {
      const input = document.createElement('input');
      input.type = 'radio';
      input.name = 'solutionFilter';  // Todos os rádios precisam ter o mesmo nome
      input.id = solution;
      input.value = solution;
      document.getElementById('filtersol').appendChild(input);

      const label = document.createElement('label');
      label.htmlFor = solution;
      label.appendChild(document.createTextNode(solution));
      document.getElementById('filtersol').appendChild(label);
      document.getElementById('filtersol').appendChild(document.createElement('br'));

      // Adiciona um ouvinte de eventos para o evento de alteração do botão de rádio
      input.addEventListener('change', () => {
        window.dispatchEvent(new CustomEvent(events.RADIO_CHANGED));
      });
    });
  }

  function isCompanyIncluded(company) {
    const selectedSolution = document.querySelector('input[name="solutionFilter"]:checked');

    // Se nenhuma solução for selecionada, deve considerar como verdadeiro (mostra todos)
    if (!selectedSolution) {
      return true;
    }

    return (company.solutions || []).includes(selectedSolution.value);
  }

  function populateCompanies() {
    document.getElementById('cards').innerHTML = '';

    companies.forEach(company => {
      if (isCompanyIncluded(company)) {
        const card = document.createElement('div');
        const hasLogo = !!company.logo;
        const endLogo = hasLogo ? require(`../img/parceiro/${company.logo}`) : '';
        const imgLogo = hasLogo ? `<img src="${endLogo}" alt="${company.name}" style="height: 40px" ;/>` : `<span class="list-group-item-text">${company.name}</span>`;
        const color = company.type;

        card.className = 'cardsfilter';
        card.innerHTML = `
          <div class="cardsfilter-${color} d-flex align-items-center justify-content-center">
            <img class="my-2 me-1" src="${badgeIntegratorIcon}" alt="${color}" style="height: 35px;"/>
            <div class="d-flex flex-column align-items-center justify-content-center">
              <img src="${badgeLogoIcon}" alt="acelera magalu"/>
              <span class="cardsfilter-title">${color}</span>
            </div>
          </div>
          <div class="cardsfilter-body row py-4 text-center">
            <a href="${company.url}" class="cards mb-1 py-3" ${company.url ? '' : 'disabled'} target="_blank"> ${imgLogo}
          </div>
        `;
        document.getElementById('cards').appendChild(card);
      }
    });
  }

  // Inicializa os botões de rádio e as empresas exibidas
  if (parentElement) {
    generateRadioButtons();
    populateCompanies();

    // Adiciona um ouvinte de eventos para o evento de alteração do botão de rádio
    window.addEventListener(events.RADIO_CHANGED, populateCompanies);
  } else {
    console.error('Elemento pai não encontrado');
  }
});
