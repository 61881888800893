import notices from '../source/comunications.json'; // Certifique-se de que o caminho esteja correto

// Eventos personalizados
const events = {
  RADIO_CHANGED: 'radio_changed',
  SEARCH_CHANGED: 'search_changed',
};

document.addEventListener('DOMContentLoaded', function() {
  const parentElement = document.getElementById('comunicatefilter'); // Elemento onde os rádios serão inseridos
  const cardsElement = document.getElementById('comunicatecards'); // Elemento onde os cards serão exibidos
  const searchInput = document.getElementById('searchInput'); // Campo de busca

  // Função para gerar botões de rádio com base nos canais
  function generateRadioButtons(filterChannels) {
    // Botão "Mostrar todos"
    const allButton = document.createElement('input');
    allButton.type = 'radio';
    allButton.name = 'channelFilter';  // Todos os rádios precisam ter o mesmo nome
    allButton.id = 'allChannels';
    allButton.value = 'all';
    parentElement.appendChild(allButton);

    const allLabel = document.createElement('label');
    allLabel.htmlFor = 'allChannels';
    allLabel.className = 'radio-label'; // Adiciona a classe para o estilo
    allLabel.appendChild(document.createTextNode('Mostrar todos'));
    parentElement.appendChild(allLabel);
    parentElement.appendChild(document.createElement('br'));

    // Adiciona um ouvinte de eventos para o botão "Mostrar todos"
    allButton.addEventListener('change', () => {
      window.dispatchEvent(new CustomEvent(events.RADIO_CHANGED));
    });

    // Botões de rádio para canais específicos
    filterChannels.forEach(channel => {
      const input = document.createElement('input');
      input.type = 'radio';
      input.name = 'channelFilter';
      input.id = channel;
      input.value = channel;
      parentElement.appendChild(input);

      const label = document.createElement('label');
      label.htmlFor = channel;
      label.className = 'radio-label'; // Adiciona a classe para o estilo
      label.appendChild(document.createTextNode(channel));
      parentElement.appendChild(label);
      parentElement.appendChild(document.createElement('br'));

      // Adiciona um ouvinte de eventos para o botão de rádio
      input.addEventListener('change', () => {
        window.dispatchEvent(new CustomEvent(events.RADIO_CHANGED));
      });
    });
  }

  // Função para filtrar os comunicados com base no canal selecionado e na busca
  function isNoticeIncluded(notice) {
    const selectedChannel = document.querySelector('input[name="channelFilter"]:checked');
    const searchQuery = searchInput.value.toLowerCase();

    // Filtra pelo canal selecionado
    const isChannelMatch = !selectedChannel || selectedChannel.value === 'all' || notice.canal === selectedChannel.value;

    // Filtra pela busca em título, descrição, data e canal
    const isSearchMatch = notice.titulo.toLowerCase().includes(searchQuery) ||
                          notice.descricao.toLowerCase().includes(searchQuery) ||
                          notice.data.toLowerCase().includes(searchQuery) ||
                          notice.canal.toLowerCase().includes(searchQuery);

    return isChannelMatch && isSearchMatch;
  }

  // Função para exibir os comunicados filtrados
  function populateNotices() {
    cardsElement.innerHTML = '';

    notices.forEach(notice => {
      if (isNoticeIncluded(notice)) {
        const card = document.createElement('div');
        const hasLogo = !!notice.logo;
        const endLogo = hasLogo ? require(`../img/${notice.logo}`) : '';
        const imgLogo = hasLogo ? `<img src="${endLogo}" alt="${notice.canal}" style="max-height: 50%;" />` : `<span class="list-group-item-text">${notice.canal}</span>`;
        const color = notice.canal;

        card.className = 'noticeCard';
                
        card.innerHTML = `
            <div class="noticeCard-${color}">

              <div class="noticeCard-header" style="display: flex; justify-content: space-between; align-items: center;">
                <h3 style="margin: 0;">${notice.titulo}</h3>
                <span>${notice.data}</span>
              </div>
              
              <br><p>${notice.descricao}</p>
              
              <div class="row" style="display: flex; justify-content: space-between">
                <div class="col-md-3">${imgLogo}</div>
                <div class="col-md-3">
                  <a href="${notice.link}" target="_blank" class="btn btn-primary text-white">Baixar Comunicado</a>
                </div>
              </div>
            </div> 
            `;

        cardsElement.appendChild(card);
      }
    });
  }

  // Inicializa os botões de rádio, a busca e os comunicados exibidos
  if (parentElement && cardsElement && searchInput) {
    const filterChannels = [...new Set(notices.map(n => n.canal))];
    generateRadioButtons(filterChannels);
    populateNotices();

    // Adiciona ouvintes de eventos para o evento de alteração do botão de rádio e busca
    window.addEventListener(events.RADIO_CHANGED, populateNotices);
    searchInput.addEventListener('input', () => {
      window.dispatchEvent(new CustomEvent(events.SEARCH_CHANGED));
    });
    window.addEventListener(events.SEARCH_CHANGED, populateNotices);
  } else {
    console.error('Elemento pai não encontrado');
  }
});
