import * as $ from 'jquery';
import 'bootstrap-table';
import integrators from '../source/integrators';
import '../img/badge-integrator.png';

const renderTable = () => {
  $('#table').bootstrapTable({
    locale:'pt-br',
    pagination: true,
    paginationHAlign: 'right',
    paginationDetailHAlign: 'right',
    paginationPreText: 'Anterior',
    paginationNextText: 'Próximo',
    paginationParts: ['pageList'],
    rowStyle: {
      classes: 'bg-white',
      css: {
        'border-right': 'none',
        'border-left': 'none'
      }
    },
    headerStyle: {
      classes: 'dynamic-table-header'
    },
    columns: [{
      field: 'name',
      title: 'Integradores',
      formatter: (item, data) => {
        const srcLogo = !!data.logo ? require(`../img/parceiro/${data.logo}`) : '';
        const content = !!data.logo ? `<img src="${srcLogo}" alt="${item}" />`
                                    : `<span>${item}</span>`
        if (!!data.url) {
          return `<a class="text-decoration-none" href="${data.url2}" target="_blank">
                    ${content}
                  </a>`
        }

        return content
      }
    }, {
      field: 'contact',
      title: 'Contato Comercial',
      formatter: (item) => {
        return `<a class="text-decoration-none" href="${item}" target="_blank">${item}</a>`
      }
    }, {
      align: 'center',
      field: 'type',
      title: 'Certificação',
      formatter: (item) => {
        if (item.toLowerCase() === "diamante") {
          return `<span class="pill pill-diamond">${item}</span>`
        }
        if (item.toLowerCase() === "ouro") {
          return `<span class="pill pill-gold">${item}</span>`
        }
        if (item.toLowerCase() === "prata") {
          return `<span class="pill pill-silver">${item}</span>`
        }

        return `<span class="pill">${item}</span>`
      }
    }],
    data: integrators
  })
};

export default renderTable;
