import * as $ from 'jquery';
import partners from '../source/integrators';
import badgeIntegratorIcon from '../img/badge-integrator.png';
import badgeLogoIcon from '../img/badge-logo.png';

const orderByList = ['Diamante', 'Ouro', 'Prata'];
const orderByColor = {
  'Diamante': 'diamond',
  'Ouro': 'gold',
  'Prata': 'silver'
};

const gridList = $('#integrator-list-mobile');

const renderItem = (items) => {
  let template = ``;

  items.forEach(item => {
    const hasLogo = !!item.logo;
    const hasLink = !!item.url;
    const srcLogo = hasLogo ? require(`../img/parceiro/${item.logo}`) : '';
    const imgLogo = hasLogo ? `<img src="${srcLogo}" alt="${item.name}" class="item-logo" />` : `<span class="list-group-item-text">${item.name}</span>`;

    template += `
      <a href="${item.url}" class="list-group-item list-group-item-action text-center ${hasLink ? '' : 'disabled'}" target="_blank">
        ${imgLogo}
      </a>
    `;
  });

  return template;
};

export const renderGridIntegrator = () => {
  let template = ``;

  orderByList.forEach(name => {
    const filteredItems = partners.filter(partner => partner.type === name);

    template += `
      <div class="grid-integrator grid-integrator-${orderByColor[name]}">
        <div class="grid-integrator-header d-flex align-items-center justify-content-center">
          <img class="my-2 me-1" src="${badgeIntegratorIcon}" alt="${name}" style="height: 60px;"/>
          <div class="d-flex flex-column align-items-center justify-content-center">
            <img src="${badgeLogoIcon}" alt="acelera magalu" />
            <span class="grid-integrator-title">${name}</span>
          </div>
        </div>

        <div class="list-group d-flex flex-wrap">
          ${renderItem(filteredItems)}
        </div>
      </div>
    `;
  });

  gridList.html(template);
};
