import * as $ from 'jquery';
import '@popperjs/core';
import 'bootstrap/dist/js/bootstrap.bundle';
import { renderFeatureList } from './feature';
import renderTable from './integrators-table';
import { createCarousel, createCarouselIntegrator } from './carousel';
import { renderGridIntegrator } from './grid-integrator';
import { createTooltip } from './tooltip';
import './filter';
import { accordeonnavlist } from './accordeondoc';
import { navbarheader } from './navheader'; 
import './comunications'; // Importa a lógica de comunicações aqui

const feature = {
  BENEFIT: 'BENEFIT',
  REQUIRIMENT: 'REQUIRIMENT'
};

const state = {
  feature: feature.BENEFIT
};

const navbarContainer = document.getElementById('navbarContainer');
if (navbarContainer) {
  navbarContainer.innerHTML = navbarheader();
}
renderFeatureList(state);

document.getElementById('accordeonListContainer').innerHTML = accordeonnavlist();

renderGridIntegrator();

const carouselIntegrator = createCarouselIntegrator();
carouselIntegrator.mount();

let carousel = createCarousel();
carousel.mount();

createTooltip();
renderTable();

const featureActions = $('.grid-features-action-item');

featureActions.on('click', function(_event) {
  const current = $(this);
  const value = current.attr('data-value');

  state.feature = value;

  featureActions.removeClass('active');
  carousel.destroy();

  current.addClass('active');

  renderFeatureList(state);

  carousel = createCarousel();
  carousel.mount();

  createTooltip();
});
