import * as $ from 'jquery';
import certification from './certification';
import '../img/badge-integrator.png';


const featureOrderList = ['Diamante', 'Ouro','Prata'];


const featureListDesktop = $('#feature-list');


const renderFeatureItem = (item) => {
  let template = '';

  if (item.value === true) {
    template = `
      ${template}
      <div class="grid-features-item">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 14" fill="currentColor">
          <path d="M6.56663 10.99L1.90048 6.82003L0.311523 8.23003L6.56663 13.82L19.9944 1.82003L18.4167 0.410034L6.56663 10.99Z" />
        </svg>
      </div>
    `;
  } else {

    template = `
      ${template}
      <div class="grid-features-item d-flex justify-content-center">
        <span class="grid-features-item-text">${item.value}</span>    
      </div>
    `;
  }

  return template;
};

const renderFeatureListItem = ({ values }) => {
  let template = ``;

  featureOrderList.forEach(featureKey => {
    const item = values[featureKey];

    template += renderFeatureItem(item);
  });

  return template;
};

const renderFeatureList = (state) => {
  const list = certification[state.feature] || [];

  let template = '';

  list.forEach(item => {
    const rowTemplate = `
      <div class="row">
        <div class="col-4 d-flex align-items-center">
          ${item.label}
        </div>
        
          ${renderFeatureListItem(item)}
        
      </div>
    `;

    template += rowTemplate;
  });

  featureListDesktop.html(template);
};


export {
  renderFeatureList
};
