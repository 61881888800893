import aceleralogo from '../img/logo.png';

export const accordeonnavlist = () => {
  return `
        
          <div class="accordion accordion-flush mb-4" id="accordionExample">

             <div class="accordion-item">
              <div class="row text-center mb-3 mt-1">
                <a class="button" href="/index.html">
                  <img src="${aceleralogo}" alt="acelera magalu" />
                </a>
                
              </div>
                <h2 class="accordion-header" id="headingOne">
                  <button class="accordion-button bg-primary text-white" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                    Introdução
                  </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body nav-content">
                    <a class="nav-link" href="/introducao.html#introducao">Introdução</a>
                    <a class="nav-link" href="/introducao.html#apihomologacao">API em Homologação</a>
                  </div>
                </div>
              </div>



              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button class="accordion-button collapsed bg-primary text-white" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false"
                    aria-controls="collapseTwo">
                    Autenticação
                  </button>
                </h2>
                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body nav-content">
                    <a class="nav-link" href="/autenticacao.html#autenticacao">Autenticação Oauth2</a>
                    <a class="nav-link" href="/autenticacao.html#contaidmagalu">Criação conta ID Magalu</a>
                    <a class="nav-link" href="/autenticacao.html#apikey">Criação da API Key</a>
                    <a class="nav-link" href="/autenticacao.html#clientoauth2">Criação do Client</a>
                    <a class="nav-link" href="/autenticacao.html#consentimento">Consentimento de Acesso</a>
                    <a class="nav-link" href="/autenticacao.html#codetoken">Troca do Code pelo Token</a>
                    <a class="nav-link" href="/autenticacao.html#refreshtoken">Refresh Token</a>
                  </div>
                </div>
              </div>


              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button class="accordion-button collapsed bg-primary text-white" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    Produtos
                  </button>
                </h2>
                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body nav-content">
                    <nav id="navbar-docs" class="navbar-light bg-light">
                      <nav class="nav nav-pills flex-column">
                        <a class="nav-link" href="/produtos.html#putprice">PUT Price</a>
                        <a class="nav-link" href="/produtos.html#postproduct">POST Product</a>
                        <a class="nav-link" href="/produtos.html#putproduct">PUT Product</a>
                        <a class="nav-link" href="/produtos.html#getproductbyid">GET Product By Id</a>
                        <a class="nav-link" href="/produtos.html#getproductall">GET Product All</a>
                        <a class="nav-link" href="/produtos.html#postsku">POST SKU</a>
                        <a class="nav-link" href="/produtos.html#putstock">PUT Stock</a>
                        <a class="nav-link" href="/produtos.html#sistemasdefila">SISTEMAS DE FILA</a>
                        <a class="nav-link" href="/produtos.html#homologacao">HOMOLOGAÇÃO</a>
                        <a class="nav-link" href="/produtos.html#perguntasfrequentes">PERGUNTAS FREQUENTES</a>
                      </nav>
                    </nav>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFour">
                  <button class="accordion-button collapsed bg-primary text-white" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false"
                    aria-controls="collapseFour">
                    Pedidos
                  </button>
                </h2>
                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body nav-content">
                    <nav id="navbar-docs" class="navbar-light bg-light">
                      <nav class="nav nav-pills flex-column">
                        <a class="nav-link" href="pedidos.html#recursosapi">Recursos da Api</a>
                        <a class="nav-link" href="pedidos.html#attribute">Attribute</a>
                        <a class="nav-link" href="pedidos.html#endpointlimit">Endpointlimit</a>
                        <a class="nav-link" href="pedidos.html#invoiced">Invoiced</a>
                        <a class="nav-link" href="pedidos.html#nfes">Nfes</a>
                        <a class="nav-link" href="pedidos.html#apiorder">Order</a>
                        <a class="nav-link" href="pedidos.html#putorder">PUT Order</a>
                        <a class="nav-link" href="pedidos.html#postshippinglabels">POST ShippingLabels</a>
                        <a class="nav-link" href="pedidos.html#postconfirmreserves">POST ConfirmReserves</a>
                        <a class="nav-link" href="pedidos.html#getorderpackage">GET OrderPackage</a>
                        <a class="nav-link" href="pedidos.html#orderqueue">ORDER QUEUE</a>
                        <a class="nav-link" href="pedidos.html#putorderqueue">PUT OrderQueue</a>
                        <a class="nav-link" href="pedidos.html#putprice">PUT Price</a>
                      </nav>
                    </nav>
                  </div>
                </div>
              </div>


              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTen">
                  <button class="accordion-button bg-primary text-white" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                    Sac
                  </button>
                </h2>
                <div id="collapseTen" class="accordion-collapse collapse" aria-labelledby="headingTen"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body nav-content">
                    <a class="nav-link" href="/sac.html#apisac">Api de Sac</a>
                    <a class="nav-link" href="/sac.html#ticketsatendimento">Buscar Tickets de Atendimento</a>
                    <a class="nav-link" href="/sac.html#ticketporlimit">Ticket por Limit</a>
                    <a class="nav-link" href="/sac.html#ticketporpedido">Ticket por Pedido</a>
                    <a class="nav-link" href="/sac.html#detalhesticket">Detalhes do Ticket</a>
                    <a class="nav-link" href="/sac.html#mensagenstickets">Enviar Mensagen ao Ticket</a>
                    <a class="nav-link" href="/sac.html#autorizarestornoticket">Autorizar Estorno de Ticket</a>
                  </div>
                </div>
              </div>


              <div class="accordion-item">
                <h2 class="accordion-header" id="headingEleven">
                  <button class="accordion-button collapsed bg-primary text-white" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false"
                    aria-controls="collapseEleven">
                    FAQ - Perguntas Frequentes
                  </button>
                </h2>
                <div id="collapseEleven" class="accordion-collapse collapse" aria-labelledby="headingEleven"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body nav-content">
                    <nav id="navbar-docs" class="navbar-light bg-light">
                      <nav class="nav nav-pills flex-column">
                        <a class="nav-link" href="/faq.html#perguntasfrequentes">PERGUNTAS FREQUENTES</a>
                      </nav>
                    </nav>
                  </div>
                </div>
              </div>
        
  `}

