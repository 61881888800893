import aceleralogo from '../img/logo.png';

export const navbarheader = () => {
  return `
  <header class="header navbar navbar-expand-lg sticky-top">
  <div class="magalu-bar"></div>
  <div class="container px-0 px-xl-3">

    <a class="navbar-brand flex-shrink-0 order-lg-1" href="/index.html">
      <img class="navbar-floating-logo d-none d-lg-block" src="${aceleralogo}" alt="acelera magalu" />
      
      <img class="d-lg-none px-4" src="${aceleralogo}" alt="acelera magalu" width="auto" />
      
    </a>
    
    <button class="navbar-toggler mt-1 me-1" type="button" data-bs-toggle="collapse" data-bs-target="#primaryMenu">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse order-lg-2" id="primaryMenu">
      <ul class="nav nav-pills">
        <li class="nav-link dropdown dropend px-3">
          Programa de Parcerias
          <div class="magalu-dropdown"</div>
          <ul class="dropdown-menu" aria-labelledby="partnershipsDropdown">
          
            <li><a class="dropdown-item" href="/integradores.html"><strong>Integradores</strong></a></li>
            <li><a class="dropdown-item" href="/pluguemagalu.html"><strong>PDV's e Frente de Caixa</strong></a></li>
          </ul>
        </li>

        
        <li class="nav-item px-3">
          <a class="nav-link" href="/solucoes.html">Soluções Homologadas</a>
        </li> 


        <div class="collapse navbar-collapse order-lg-2" id="primaryMenu">
          <ul class="nav nav-pills">
            <li class="nav-link dropdown dropend px-3">
              Documentações
            <div class="magalu-dropdown"</div>
            <ul class="dropdown-menu" aria-labelledby="partnershipsDropdown">
            
              <li><a class="dropdown-item" href="/introducao.html"><strong>API Docs</strong></a></li>
              <li><a class="dropdown-item" href="/comunications.html"><strong>Historico de Alterações</strong></a></li>
              <li><a class="dropdown-item" href="/suporte.html"><strong>Suporte</strong></a></li>
            </ul>
          </li>


        <li class="nav-item px-3">

      </ul>
    </div>
  </div>
</header>


  `;
};
