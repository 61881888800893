import Glide from '@glidejs/glide';

export const createCarousel = () => {
  return new Glide('.glide-feature', {
    hoverpause: true,
    peek: {
      before: 0, 
      after: 4
    },
  });
};

export const createCarouselIntegrator = () => {
  return new Glide('.glide-integrator', {
    hoverpause: true,
    peek: {
      before: 0, 
      after: 4
    },
  });
};
